import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import View from '../../components/View';
import '../../components/View/View.scss';
import { belongsToMyClient, formatUrl, getToken } from '../../helpers/functions';
import c, { auctionCardTypes,  CardType } from '../../helpers/constants';
import { useHistory } from 'react-router-dom';
import { fetchAuctionsPlusPublic, updateAuction } from '../../actions/Auction';
import { getAuctions, isFetchingAuctions } from '../../selectors/Auction';
import Card from '../../components/Card/Card';
import { useEditMode } from '../../hooks/EditModeHook';
import DataTable from '../../components/DataTable';
import { toNumber } from 'lodash';
import DropDown from '../../components/DropDown';
import Modal from '../../components/Modal';
import { getLoggedInRole, getLoggedInUser } from '../../selectors/Auth';
import { Alert } from 'react-bootstrap';

function AuctionHub() {
  const auctions = useSelector(getAuctions);
  const isLoading = useSelector(isFetchingAuctions);
  const userRole = useSelector(getLoggedInRole);
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchMode, searchWord] = useEditMode(c.APP_ROUTES.SEARCH_AUCTIONS);
  const [accessLevelModal, setAccessLevel] = useState(2000);
  const [statusModal, setStatus] = useState('Private');
  const [isMadeByMeModal, setIsMadeByMe] = useState(false);
  const [auctionIdModal, setAuctionIdModal] = useState('');
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const canCreateAuctions = (getToken() && userRole && userRole.accessLevel < 2000);
  const [refetch, setRefetch] = useState(false);
  const user = useSelector(getLoggedInUser);
  const queryParams = new URLSearchParams(location.search);
  const clientParam = queryParams.get('client');
  const itemsStatus = auctionCardTypes?.map(r => ({ label: r.label, value: r.value })) ;
  const [showCopied, setShowCopied] = useState(false);

  const onView = (auctionId: string) => {
    if (searchWord) {
      history.push(`${formatUrl(c.APP_ROUTES.VIEW_AUCTION, auctionId)}?search=${searchWord}`);
    } else {
      history.push(formatUrl(c.APP_ROUTES.VIEW_AUCTION, auctionId));
    }
  };

  const onCreateNewAuction = () => {
    history.push(c.APP_ROUTES.AUCTION_CREATE_STEP_ONE);
  };

  const config = {
    columns: [ { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: true },
      { key: 'created', label: 'Time Created', mobileFriendly: true },
      { key: 'updated', label: 'Last Updated', mobileFriendly: true },
    ],
    actions: [],
    hideButton: true,
  };

  const setStatusModal = (accessLevel: number, status: string, isMadeByMe: boolean, auctionId: string) => {
    setAccessLevel(accessLevel);
    setStatus(status);
    setIsMadeByMe(isMadeByMe);
    setAuctionIdModal(auctionId);
    setOpenStatusModal(true);
  };

  const onDismissStatusModal = () => {
    setOpenStatusModal(false);
  };

  const onStatusSelect = (obj: any, auctionId: string) => {
    dispatch(updateAuction(auctionId, '', '', '', '', '', '', '', '', '', obj, ''));
    setOpenStatusModal(false);
    setRefetch(!refetch);
  };

  const onShare = (row: any) => {
    const rootUrl = window.location.origin;
    const relativeUrl = formatUrl(c.APP_ROUTES.VIEW_AUCTION, row.id);
    const fullUrl = `${rootUrl}${relativeUrl}`;

    navigator.clipboard.writeText(fullUrl);
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 4000);
  };

  return (
        <View title='AUCTIONS' onAddNew={canCreateAuctions ? onCreateNewAuction : undefined} >
          {showCopied && <Alert className="mt-3" variant="success">
            Guest link copied to clipboard successfully!
          </Alert>}
            <DataTable paging={auctions?.paging || []} data={auctions?.data || []} config={config}
                       exportButton={false}
                       isLoading={isLoading}
                       fetchFunction={fetchAuctionsPlusPublic}
                       baseSearch={ clientParam ? { 'clientTitle': searchWord } : { 'all': searchMode ? searchWord : '' }}
                       isTable={false}
                       dependencies={[searchWord, refetch]}
                       lineItemTemplate={(row: any) =>
                           <div key={row.id} className="col-md-6 col-sm-6 mb-4">
                               <Card
                                   cardId={row.index}
                                   title={row.title}
                                   onShare={() => onShare(row)}
                                   description={row.description}
                                   imageUrl={`${c.API_ENDPOINTS.ASSET_FILE}/${row.assetId}`}
                                   cardType={CardType.AUCTION}
                                   status={row.status}
                                   onAuctionStatusClick={() => setStatusModal(toNumber(userRole?.accessLevel), row.status, row.createdBy == user?.id, row.id)}
                                   auctionStatus={(user && userRole && userRole?.accessLevel <= 1000 && belongsToMyClient(user, row.clientId)) && row.visibility} 
                                   secondaryLogo={row.secondaryLogo ? `${c.API_ENDPOINTS.ASSET_FILE}/${row.secondaryLogo}` : `${c.API_ENDPOINTS.ASSET_FILE}/${row.primaryLogo}`}
                                   onClick={() => onView(row.id)}
                               />
                           </div>
                       }
            />
            <Modal show={openStatusModal} title={'Choose Status'} onHide={onDismissStatusModal}>
                {
                    (accessLevelModal < 2000 || isMadeByMeModal) &&
                    <div><DropDown placeholder="Auction Status" type="default" items={itemsStatus}
                                   value={statusModal} onSelect={(e) => onStatusSelect(e.value, auctionIdModal)}/>
                    </div>
                }
            </Modal>
        </View>
  );


}

export default AuctionHub;
