import React, { useEffect, useState } from 'react';
import View from '../../components/View';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveUser, getUsers } from '../../selectors/User';
import { fetchActiveUser } from '../../actions/User';
import { getUserToken } from '../../helpers/functions';
import c from '../../helpers/constants';
import './UserProfile.scss';
import avatarPlaceholder from './avatar-placeholder.png';
import plants from './laptop-plants.svg';
import assetGallery from '../../components/AssetGallery';
import Tabs from '../../components/Tabs';
import AccountInformation from './profile-components/AccountInformation';
import ChangePassword from './profile-components/ChangePassword';
import MyAuctions from './profile-components/MyAuctions';
import MyBids from './profile-components/MyBids';
import FavouriteTags from './profile-components/FavouriteTags';
import ProfilePicture from './profile-components/ProfilePicture';
import Subscriptions from './profile-components/Subcriptions';
import { useLocation, useHistory } from 'react-router-dom';
import { getLoggedInRole } from '../../selectors/Auth';

const UserProfile = () => {
  const activeUser = useSelector(getActiveUser);
  const loggedInRole = useSelector(getLoggedInRole);
  const loadUser = useSelector(getUsers);
  const dispatch = useDispatch();

  const [avatar, setAvatar] = useState(activeUser?.avatar || '');

  // React Router hooks to manage the URL hash in v5
  const location = useLocation();
  const history = useHistory();

  // Define the tabs with corresponding hash names
  const tabs = [
    {
      label: 'Account Information',
      content: <AccountInformation />,
      hash: '#account-info',
    },
    {
      label: 'Change Password',
      content: <ChangePassword />,
      hash: '#change-password',
    },
    ...((loggedInRole && loggedInRole?.accessLevel === 0)
      ? [{
        label: 'Subscription',
        content: <Subscriptions />,
        hash: '#subscription',
      }]
      : []),
    {
      label: 'My Auctions',
      content: <MyAuctions />,
      hash: '#my-auctions',
    },
    {
      label: 'My Bids',
      content: <MyBids />,
      hash: '#my-bids',
    },
    {
      label: 'Favourite Tags',
      content: <FavouriteTags />,
      hash: '#favourite-tags',
    },
    ...((loggedInRole && loggedInRole?.accessLevel <= 2000)
      ? [{
        label: 'Profile Picture',
        content: <ProfilePicture />,
        hash: '#profile-picture',
      }]
      : []),
  ];

  // Get the current tab based on the URL hash
  const getActiveTabFromHash = () => {
    const hash = location.hash || '#account-info'; // Default to the first tab if no hash
    const foundTab = tabs.findIndex(tab => tab.hash === hash);
    return foundTab !== -1 ? foundTab : 0; // Default to 0 if hash is invalid
  };

  const [activeTab, setActiveTab] = useState(getActiveTabFromHash);

  // Sync tab state with URL hash
  useEffect(() => {
    const tabIndex = getActiveTabFromHash();
    if (tabIndex !== activeTab) {
      setActiveTab(tabIndex);
    }
  }, [location.hash, activeTab, tabs]);

  // Fetch user on component mount
  useEffect(() => {
    dispatch(fetchActiveUser(getUserToken()));
  }, [dispatch, loadUser]);

  // Update avatar when activeUser or loadUser changes
  useEffect(() => {
    if (activeUser) {
      setAvatar(activeUser?.avatar);
    }
  }, [activeUser, loadUser]);

  // Handle tab change and update the URL hash using useHistory
  const handleTabChange = (newTabIndex: any) => {
    setActiveTab(newTabIndex);
    history.push(`${tabs[newTabIndex].hash}`); // Update the URL hash with the new tab
  };

  return (
      <div style={{ height: '100%' }}>
        <View title={'USER PROFILE'}>
          <div className='user-profile__frame-circles'>
            <img src={assetGallery.bgCirclesImg} alt='bg-circles' />
          </div>
          <div className="user-profile__content" style={{ height: '100%' }}>
            <div className="user-profile__position">
              {avatar ? (
                  <div className="user-profile__frame-avatar">
                    <img
                        className={'user-profile__frame-avatar--img'}
                        src={`${c.API_ENDPOINTS.ASSET_FILE}/${avatar}`}
                        alt="User Avatar"
                    />
                  </div>
              ) : (
                  <div className="user-profile__frame-avatar">
                    <img
                        src={avatarPlaceholder}
                        alt="User Avatar"
                        className={'user-profile__frame-avatar--img'}
                    />
                  </div>
              )}
            </div>
            {/* Pass activeTab and handleTabChange to Tabs component */}
            <Tabs
                tabs={tabs}
                activeTabIndex={activeTab}
                onTabChange={handleTabChange}
            />
          </div>
          <div className="background-image">
            <img src={plants} alt="Background Image" />
          </div>
        </View>
      </div>
  );
};

export default UserProfile;
