import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getAuctions, isFetchingAuctions } from '../../../selectors/Auction';
import { activateAuction, fetchAuctions, storeAuction } from '../../../actions/Auction';
import { Auction } from '../../../types/Auction';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const AuctionDataTable = () => {

  const auctions = useSelector(getAuctions);
  const isLoading = useSelector(isFetchingAuctions);
  const [toggle, setToggle] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);
  const [auction, setActiveAuction] = useState<Auction | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.AUCTION_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.AUCTION_UPDATE, row.id));
  };

  const onArchive = (row: Auction) => {
    setActiveAuction(row);
    setToggle(true);
  };

  const onUnArchive = (row: Auction) => {
    setActiveAuction(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (auction?.id) {
      if (unArchiveToggle){
        await activateAuction(auction?.id);
      } else {
        await storeAuction(auction?.id);
      }
      setRefetch(!refetch);
    }
    setActiveAuction(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [ { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: true },
      { key: 'status', label: 'Status', mobileFriendly: true },
      { key: 'startingBid', label: 'Starting Bid', mobileFriendly: true },
      { key: 'bidIncrement', label: 'Bid Increment', mobileFriendly: true },
      { key: 'currency', label: 'Currency', mobileFriendly: true },
      { key: 'startDateTime', label: 'Start Time', mobileFriendly: true },
      { key: 'endDateTime', label: 'End Time', mobileFriendly: true },
      { key: 'created', label: 'Time Created', mobileFriendly: true },
      { key: 'updated', label: 'Last Updated', mobileFriendly: true },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: 'Archive', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive }],
    hideButton: true,
  };

  return (
      <View title="Auctions" onAddNew={onCreate}>
        <DataTable fetchFunction={fetchAuctions} paging={auctions?.paging || []}
                   isLoading={isLoading}  data={auctions?.data || []} config={config} dependencies={refetch} />
        <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive Asset' : 'Archive Asset'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
          {unArchiveToggle ? 'Are you sure you want to unarchive this entry?' : 'Are you sure you want to archive this entry?'}
        </Modal>
      </View>
  );
};

export default AuctionDataTable;

