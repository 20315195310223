import { ApiDataModel, FormError } from './Common';

export const FETCH_AUCTIONS_REQUEST = 'FETCH_AUCTIONS_REQUEST';
export const FETCH_AUCTIONS_RESPONSE = 'FETCH_AUCTIONS_RESPONSE';
export const FETCH_AUCTIONS_FAILURE = 'FETCH_AUCTIONS_FAILURE';

export const FETCH_AUCTION_REQUEST = 'FETCH_AUCTION_REQUEST';
export const FETCH_AUCTION_RESPONSE = 'FETCH_AUCTION_RESPONSE';
export const FETCH_AUCTION_FAILURE = 'FETCH_AUCTION_FAILURE';

export const POST_AUCTION_REQUEST = 'POST_AUCTION_REQUEST';
export const POST_AUCTION_RESPONSE = 'POST_AUCTION_RESPONSE';
export const POST_AUCTION_FAILURE = 'POST_AUCTION_FAILURE';

export interface Auction {
  groupId: string,
  clientId: string,
  id: string,
  title: string,
  description: string,
  assetId: string,
  startingBid: string,
  startDateTime: string,
  endDateTime: string,
  bidIncrement: string,
  visibility: string,
  currency: string,
  status: string,
  created: Date;
  createdBy: string;
  updated: Date;
  updateBy: string;
  archived: Date;
  archivedBy: string;
}

export interface AuctionDataModel extends Omit<ApiDataModel, 'data'> {
  data: Auction[];
}

export interface AuctionState {
  auctions: AuctionDataModel | null,
  activeAuction: Auction | null,
  createdAuction: string | null,
  isFetchingAuctions: boolean;
  fetchingAuctionsFailed: boolean;
  postingAuctionFailed?: boolean;
  isPostingAuction: boolean;
  postingAuctionValidationErrors: FormError[] | null;
  postingAuctionError: string | null;
}

interface FetchAuctionsRequestAction {
  type: typeof FETCH_AUCTIONS_REQUEST;
  payload: null;
}

interface FetchAuctionsResponseAction {
  type: typeof FETCH_AUCTIONS_RESPONSE;
  payload: AuctionDataModel;
}

interface FetchAuctionsFailureAction {
  type: typeof FETCH_AUCTIONS_FAILURE;
  payload: null;
}

interface FetchAuctionRequestAction {
  type: typeof FETCH_AUCTION_REQUEST;
  payload: null;
}

interface FetchAuctionResponseAction {
  type: typeof FETCH_AUCTION_RESPONSE;
  payload: Auction;
}

interface FetchAuctionFailureAction {
  type: typeof FETCH_AUCTION_FAILURE;
  payload: null;
}

interface PostAuctionRequestAction {
  type: typeof POST_AUCTION_REQUEST;
  payload: null;
}

interface PostAuctionResponseAction {
  type: typeof POST_AUCTION_RESPONSE;
  payload: string | null;
}

interface PostAuctionFailureAction {
  type: typeof POST_AUCTION_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type AuctionActionTypes =
    | FetchAuctionsRequestAction
    | FetchAuctionsResponseAction
    | FetchAuctionsFailureAction
    | PostAuctionRequestAction
    | PostAuctionResponseAction
    | PostAuctionFailureAction
    | FetchAuctionRequestAction
    | FetchAuctionResponseAction
    | FetchAuctionFailureAction;
